import classNames from 'classnames';
import { motion } from 'framer-motion';

interface Props {
  size?: 'small';
  gray?: boolean;
}
export const Loader = ({ size, gray }: Props): JSX.Element => {
  return (
    <motion.div
      className={classNames('border-t-transparent rounded-full', {
        'w-6 h-6 border-2': size === 'small',
        'w-12 h-12 border-4': size !== 'small',
        'border-gray-300': gray,
        'border-white': !gray,
      })}
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
    />
  );
};
